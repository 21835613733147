var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-card',{staticClass:"app_nav_bar",attrs:{"color":"transparent","outlined":"","tile":""}},[_c('v-img',{attrs:{"src":_vm.logo,"lazy-src":"https://image.ibb.co/cVTEsG/eAcademy.png","aspect-ratio":1}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)],1),_c('v-divider'),_c('v-list',{staticClass:"mb-3",attrs:{"dense":""}},[_vm._l((_vm.nav),function(menu,key){return [(
            (menu.permission === true || _vm.$auth.can(menu.permission)) &&
              menu.children &&
              menu.children.length > 0
          )?_c('v-list-group',{key:key,attrs:{"no-action":"","prepend-icon":menu.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(menu.name))])],1)]},proxy:true}],null,true)},[_vm._l((menu.children),function(child,childKey){return [(
                _vm.$auth.can(child.permission) &&
                  child.children &&
                  child.children.length > 0 &&
                  (!child.callBackFunc || child.callBackFunc())
              )?_c('v-list-group',{key:childKey,attrs:{"sub-group":"","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(child.name))])],1)]},proxy:true}],null,true)},[_vm._l((child.children),function(c,k){return [(
                    _vm.$auth.can(c.permission) &&
                      ((c.callBackFunc && c.callBackFunc(c)) ||
                        typeof c.callBackFunc === 'undefined')
                  )?_c('v-list-item',{key:k,staticClass:"nav_deep",staticStyle:{"padding-left":"65px"},attrs:{"ripple":"","to":c.path}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(c.name)+" ")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(c.icon ? c.icon : "remove"))])],1)],1):_vm._e()]})],2):(
                child.permission === true ||
                  (_vm.$auth.can(child.permission) &&
                    (!child.callBackFunc || child.callBackFunc()) &&
                    child.path === 'result-blocked-student-list' &&
                    (_vm.$auth.company().id === 5 || _vm.$auth.company().id === 1))
              )?_c('v-list-item',{key:childKey,attrs:{"ripple":"","to":child.path}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(child.name)+" ")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(child.icon ? child.icon : "remove"))])],1)],1):(
                child.permission === true ||
                  (_vm.$auth.can(child.permission) &&
                    (!child.callBackFunc || child.callBackFunc()) &&
                    child.path !== 'result-blocked-student-list')
              )?_c('v-list-item',{key:childKey,attrs:{"ripple":"","to":child.path}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(child.name)+" ")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(child.icon ? child.icon : "remove"))])],1)],1):_vm._e()]})],2):(
            (menu.permission === true || _vm.$auth.can(menu.permission)) &&
              (!menu.callBackFunc || menu.callBackFunc())
          )?_c('v-list-item',{key:key,attrs:{"ripple":"","to":menu.path}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(menu.icon ? menu.icon : "remove"))])],1),_c('v-list-item-content',{on:{"click":function($event){return _vm.menuClickedAction(menu.name)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(menu.name)+" ")])],1)],1):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }