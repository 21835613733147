import NavFactory from "../../../library/NavFactory";

let routes = [
    {
        name: 'Learning Materials',
        icon: 'dynamic_feed',
        path: '/learning-materials',
        permission: 'learning-materials-read',
        // permission: 'batch-read|batch-create|section-read|subject-read|subject-routine-read|attendance-read|assignment-read',
        // callBackFunc: NavFactory.isDev,
    }
];

export default routes;