let routes = [
    {
        name: 'ID Card',
        icon: 'stay_primary_landscape',
        header: true,
        // permission: 'fee-heads-read|fee-read|fee-offer-read|bill-generate-student-wise|bill-generate-class-wise',
        permission: 'grade-create',
        children: [{
            name: 'Student',
            path: '/student-card',
            icon: 'stay_primary_landscape',
            permission: 'grade-create'
        }, {
            name: 'Staff',
            path: '/staff-card',
            icon: 'insert_photo',
            permission: 'grade-create'
        }]
    }
];

export default routes;