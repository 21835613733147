import authenticationMenu from './authentication/_routes/menu'
import dashboardMenu from './dashboard/_routes/menu'
import departmentMenu from './department/_routes/menu'
import guardianModuledMenu from './guardianModule/_routes/menu'
import schoolGuardianMenu from './schoolGuardianAssign/_routes/menu'
import transitMenu from './transit/_routes/menu'
import academicMenu from './academic/_routes/menu'
import studentMenu from './students/_routes/menu'
import examMenu from './exam/_routes/menu'
import accountMenu from './account/_routes/menu'
import onlineRegistration from './onlineRegistration/_routes/menu'
import billingMenu from './billing/_routes/menu'
import miscellaneousMenu from './miscellaneous/_routes/menu'
import transportationMenu from './transportation/_routes/menu'
import cardMenu from './idcard/_routes/menu'
import activityReportMenu from './activityReport/_routes/menu'
import aio from './aio/_routes/menu'
import sms from './sms/_routes/menu'
import calendar from './calendar/_routes/menu'
import auth from "../library/Auth";
import conference from "./conference/_routes/menu"
import studyMaterials from "./learningMaterials/_routes/menu"
let menus = [];
let SMSmenus = [];
if (auth.loggedInRole() && auth.loggedInRole().slug === 'administrator') {
    SMSmenus = [...sms]
}


if (auth.loggedInRole() && auth.loggedInRole().slug === 'guardian') {
    menus = [...dashboardMenu, ...guardianModuledMenu, ...calendar,];
} else {
    menus = [...dashboardMenu, ...departmentMenu, ...aio, ...schoolGuardianMenu, ...authenticationMenu, ...transitMenu, ...academicMenu, ...studentMenu, ...cardMenu, ...examMenu, ...transportationMenu, ...billingMenu, ...accountMenu, ...calendar, ...miscellaneousMenu, ...activityReportMenu, ...onlineRegistration, ...conference, ...studyMaterials].concat(SMSmenus);
}

export default menus;