import NavFactory from "@/library/NavFactory";

let routes = [
    {
        name: 'Academic',
        icon: 'school',
        header: true,
        // permission: 'batch-read|grade-read|section-read|subject-read|subject-routine-read|attendance-read|assignment-read',
        permission: 'batch-read|grade-read|section-read|subject-read|subject-routine-read|attendance-read|assignment-read',
        children: [
            {
                name: 'Setting',
                path: '',
                icon: '',
                permission: 'grade-read|batch-read|subject-read|subject-routine-read|staff-create',
                children: [
                    {
                        name: 'Batches',
                        path: '/batches',
                        icon: 'insert_invitation',
                        permission: 'batch-read'
                    },
                    {
                        name: 'Grades',
                        path: '/grades',
                        icon: 'layers',
                        permission: 'grade-read',

                    },
                    {
                        name: 'Subjects',
                        path: '/subjects',
                        icon: 'book',
                        permission: 'subject-read'
                    }, {
                        name: 'Syllabus',
                        path: '/syllabus',
                        icon: 'book',
                        permission: 'subject-read'
                    },
                    {
                        name: 'Routines',
                        path: '/routines',
                        icon: 'list_alt',
                        permission: 'subject-routine-read'
                    },
                    {
                        name: 'Migrate Routines',
                        path: '/migrate-routines',
                        icon: 'swap_vert',
                        permission: 'subject-create'
                    },
                    {
                        name: 'Optional Group',
                        path: '/opt-group',
                        icon: 'ballot',
                        permission: 'staff-create'
                    },/*{
                        name: 'Department',
                        path: '/department',
                        icon: 'list_alt',
                        permission: 'staff-create'
                    }*/
                ]
            },
            {
                name: 'Activity',
                path: '',
                icon: '',
                permission: 'attendance-read|assignment-read',
                children: [
                    {
                        name: 'Attendance',
                        path: '/attendance',
                        icon: 'how_to_reg',
                        permission: 'attendance-read',
                        callBackFunc: NavFactory.isClassTeacher
                    },
                    {
                        name: 'Assignments',
                        path: '/assignments',
                        icon: 'chrome_reader_mode',
                        permission: 'assignment-read'
                    },
                ]
            },
            {
                name: 'Report',
                path: '',
                icon: '',
                permission: 'staff-create',
                children: [
                    {
                        name: 'Attendance Report',
                        path: '/attendance-report',
                        icon: 'ballot',
                        permission: 'staff-create'
                    }, {
                        name: 'Assignment Report',
                        path: '/assignment-report',
                        permission: "staff-create",
                        icon: 'ballot',
                    }
                ]
            },


        ]
    }];

export default routes;