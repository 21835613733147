let routes = [
    {
        name: 'Assignment',
        icon: 'chrome_reader_mode',
        path: '/guardian-assignment',
        permission: true
    },
    {
        name: 'Exam',
        icon: 'view_carousel',
        permission: true,
        children: [
            {
                name: 'Exam Routine',
                icon: 'list_alt',
                path: '/guardian-exam-routine',
                permission: true
            },
            {
                name: 'Mark sheet',
                icon: 'table_chart',
                path: '/guardian-result',
                permission: true
            }
        ]
    },
    {
        name: 'Routine',
        icon: 'list_alt',
        path: '/guardian-class-routine',
        permission: true
    },
    {
        name: 'Syllabus',
        icon: 'list_alt',
        path: '/guardian/sl',
        permission: true
    },
    {
        name: 'Attendance',
        icon: 'list_alt',
        path: '/guardian/att',
        permission: true
    },
    {
        name: 'Learning Materials',
        icon: 'list_alt',
        path: '/guardian/lm',
        permission: true
    },
    // {
    //     name: 'Live Class',
    //     icon: 'list_alt',
    //     path: '/guardian/lc',
    //     permission: true
    // },
    {
        name: 'Notice',
        icon: 'list_alt',
        path: '/guardian/nt',
        permission: true
    },
];

export default routes;