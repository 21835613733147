<template>
  <div>
    <v-dialog
      v-model="paymentAlert.paymentAlertModel"
      persistent
      max-width="570"
    >
      <v-card class="primary--border">
        <v-card-title
          :class="
            'title pa-3 white--text ' + paymentAlert.pendingInvoice.action_type
          "
        >
          <v-icon class="mr-2 white--text">warning</v-icon>
          Payment, Alert!!! (<span
            v-if="paymentAlert.pendingInvoice.action_type === 'error'"
            >CRITICAL</span
          ><span
            v-else-if="paymentAlert.pendingInvoice.action_type === 'success'"
            >NOTICE</span
          ><span v-else>WARNING</span>)
        </v-card-title>
        <v-card-text class="ma-0">
          <br />
          <alert-message
            :extra-small="true"
            :type="paymentAlert.pendingInvoice.action_type"
            title="Payment Alert."
            :message="paymentAlert.alertMessage"
          ></alert-message>
          <br />

          <div class="plan_info">
            <div class="pi_content plan_info_title">Plan Duration</div>
            <div class="pi_content plan_info_amount">
              {{ paymentAlert.pendingInvoice.plan_duration }} Month
            </div>
          </div>
          <v-divider></v-divider>

          <template
            v-for="(payment, key) in paymentAlert.pendingInvoice.payment_heads"
          >
            <div class="plan_info" :key="key + 200">
              <div class="pi_content plan_info_title">{{ payment.title }}</div>
              <div class="pi_content plan_info_amount">
                {{ payment.amount.numberFormat() }}
              </div>
              <!--{{totalPaymentAmount+=head.amount}}-->
            </div>
            <v-divider :key="key + 100"></v-divider>
          </template>

          <div class="plan_info">
            <div class="pi_content plan_info_title">Total Amount</div>
            <div class="pi_content plan_info_amount">
              {{ paymentAlert.totalPaymentAmount.currency() }}
            </div>
          </div>

          <br />
          <v-btn
            class="mb-3"
            block
            outlined
            large
            color="success"
            small
            @click="
              remindLaterAlert(paymentAlert.pendingInvoice),
                $router.push({ name: 'settings', query: { tab: 'payments' } })
            "
          >
            Pay Now
          </v-btn>

          <v-btn
            color="warning"
            block
            large
            :disabled="false"
            outlined
            @click="remindLaterAlert(paymentAlert.pendingInvoice)"
          >
            <span v-if="paymentAlert.pendingInvoice.action_type === 'error'">
              CLOSE ALERT!! I DON'T CARE
            </span>
            <span v-else>
              REMIND ME AFTER AFTER 3 DAYS
            </span>
          </v-btn>

          <br />
          <small
            ><strong>NOTE:</strong> Alert is only shown to administrator and
            accountant members.</small
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import cookie from "js-cookie";
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({}),
  mounted() {
    this.getPendingPayment();
  },
  computed: {
    ...mapState(["paymentAlert"]),
  },
  methods: {
    ...mapActions(["getPendingPayment", "updateReminder"]),

    remindLaterAlert(payment) {
      this.updateReminder({
        alert_type: payment.alert_type,
        id: payment.id,
      })
        .then((res) => {
          cookie.set("_al_ch_for", res.data.remind_later_flag, { expires: 8 });
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: "Something went wrong please contact support",
            status: "error",
          });
        });
      //   console.log(payment);
      //   cookie.set("_al_ch_for", payment.today_iso, { expires: 1 });
    },
  },
};
</script>
<style lang="scss">
.plan_info {
  display: flex;
  margin: 20px 0;
  .pi_content {
    flex: 1;
    font-size: 17px;
    font-weight: 500;
  }
  .plan_info_amount {
    text-align: right;
    color: #666;
    font-size: 18px;
    font-weight: bold;
  }
  .plan_info_title {
    color: #939a9f;
  }
}
</style>
