import NavFactory from "../../../library/NavFactory";
let routes = [
    {
        name: 'Live Class',
        icon: 'cast',
        path: '/live-class', 
        permission: 'live-class-read',
        // permission: 'batch-read|batch-create|section-read|subject-read|subject-routine-read|attendance-read|assignment-read',
        // callBackFunc: NavFactory.toSchool,
    }
];

export default routes;