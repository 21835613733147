let routes = [{
    name: 'Students',
    icon: 'group',
    header: true,
    permission: 'student-read|student-create|student-promote',
    children: [
        {
            name: 'Search',
            path: '/search-students',
            icon: 'search',
            permission: 'student-read'
        },
        {
            name: 'Admit',
            path: '/admit',
            icon: 'person_add',
            permission: 'student-create'
        },
        {
            name: 'Promote',
            path: '/promote',
            icon: 'plus_one',
            permission: 'student-promote'
        },
        {
            name: 'Left',
            path: '/search-students?list_type=LEFT',
            icon: 'delete',
            permission: 'student-promote'
        }
    ]
}];

export default routes;