<template>
  <v-flex>
    <v-card color="transparent" class="app_nav_bar" outlined tile>
      <v-img
        :src="logo"
        lazy-src="https://image.ibb.co/cVTEsG/eAcademy.png"
        :aspect-ratio="1"
      >
        <v-layout
          slot="placeholder"
          fill-height
          align-center
          justify-center
          ma-0
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-layout>
      </v-img>
      <v-divider />
      <v-list dense class="mb-3">
        <template v-for="(menu, key) in nav">
          <v-list-group
            :key="key"
            no-action
            v-if="
              (menu.permission === true || $auth.can(menu.permission)) &&
                menu.children &&
                menu.children.length > 0
            "
            :prepend-icon="menu.icon"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ menu.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="(child, childKey) in menu.children">
              <v-list-group
                sub-group
                no-action
                :key="childKey"
                v-if="
                  $auth.can(child.permission) &&
                    child.children &&
                    child.children.length > 0 &&
                    (!child.callBackFunc || child.callBackFunc())
                "
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ child.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template
                  style="padding-left: 50px;"
                  v-for="(c, k) in child.children"
                >
                  <v-list-item
                    v-if="
                      $auth.can(c.permission) &&
                        ((c.callBackFunc && c.callBackFunc(c)) ||
                          typeof c.callBackFunc === 'undefined')
                    "
                    class="nav_deep"
                    style="padding-left: 65px"
                    ripple
                    :key="k"
                    :to="c.path"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ c.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>{{ c.icon ? c.icon : "remove" }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list-group>
              <v-list-item
                v-else-if="
                  child.permission === true ||
                    ($auth.can(child.permission) &&
                      (!child.callBackFunc || child.callBackFunc()) &&
                      child.path === 'result-blocked-student-list' &&
                      ($auth.company().id === 5 || $auth.company().id === 1))
                "
                ripple
                :key="childKey"
                :to="child.path"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>{{ child.icon ? child.icon : "remove" }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                v-else-if="
                  child.permission === true ||
                    ($auth.can(child.permission) &&
                      (!child.callBackFunc || child.callBackFunc()) &&
                      child.path !== 'result-blocked-student-list')
                "
                ripple
                :key="childKey"
                :to="child.path"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon small>{{
                    child.icon ? child.icon : "remove"
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-item
            ripple
            :key="key"
            :to="menu.path"
            v-else-if="
              (menu.permission === true || $auth.can(menu.permission)) &&
                (!menu.callBackFunc || menu.callBackFunc())
            "
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon ? menu.icon : "remove" }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="menuClickedAction(menu.name)">
              <v-list-item-title>
                {{ menu.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-flex>
</template>
<script>
import menus from "@/modules/Menus";
import cookie from "js-cookie";
import { mapActions, mapMutations, mapState } from "vuex";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();

export default {
  data: () => ({
    items: [],
    itemsData: ["Foo", "Bar", "Fizz", "Buzz"],
    sId: "",
  }),
  watch: {
    sId: function(v) {
      if (!this.sId) this.sId = this.selectedStudentId;
      this.setGlobalSelectedStudent();
    },
  },
  computed: {
    ...mapState(["guardian", "selected_student"]),

    nav() {
      return [...this.items, ...menus];
    },
    current() {
      return this.$route.name;
    },
    logo() {
      return cookie.get("_cl") || "https://image.ibb.co/cVTEsG/eAcademy.png";
    },
    childrenData() {
      return this.guardian.children.map((res) => {
        return {
          text: res.full_name,
          value: res.id,
          studentInfo: res.academic ? res.academic : "test",
        };
      });
    },
  },
  mounted() {},
  created() {
    this.sId =
      this.guardian.children.length > 0 ? this.guardian.children[0].id : "";
  },
  methods: {
    ...mapActions(["setSelectedStudent"]),
    ...mapMutations(["SIDEBAR_MV"]),
    goto(menu) {
      if (menu.callee && typeof menu.callee === "function") {
        menu.callee();
      } else {
        const path = menu.path;

        if (path && this.$route.name !== menu.path)
          this.$router.push({ name: path });
      }
    },
    menuClickedAction(menuName) {
      if (menuName === "AIO") {
      }
    },
    viewStudent() {
      this.setGlobalSelectedStudent();
    },
    setGlobalSelectedStudent() {
      let selectedStudent = this.guardian.children.find(
        (res) => res.id === this.sId
      );
      this.setSelectedStudent(selectedStudent);
    },
  },
};
</script>
<style lang="scss">
.what_new {
  position: absolute;
  bottom: 0;
}
.app_nav_bar {
  .v-list__group__items--no-action .v-list__tile {
    padding-left: 40px !important;
  }

  .nav_deep {
    .v-list__tile__action,
    .v-list__tile__avatar {
      min-width: 30px;
    }
  }
}
</style>
