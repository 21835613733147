import NavFactory from "../../../library/NavFactory";

let routes = [{
    name: 'Examination',
    icon: 'event_note',
    header: true,
    permission: 'exam-read|routine-read|admit-card-generate-bulk|admit-card-generate-single|subject-mark-read|marks-input-read',
    children: [
        {
            name: 'Settings',
            path: '/exam',
            icon: 'grain',
            permission: 'exam-read',
            children: [
                {
                    name: 'Exam',
                    path: '/exam',
                    icon: 'grain',
                    permission: 'exam-read'
                },
                {
                    name: 'Routine',
                    path: '/exam-routine',
                    icon: 'list_alt',
                    permission: 'routine-read',
                },
                {
                    name: 'Subject Marks',
                    path: '/subject-marks',
                    icon: 'select_all',
                    permission: 'subject-mark-read',
                },
                {
                    name: 'P/A Manager',
                    path: '/pa-manager',
                    icon: 'directions_run',
                    permission: 'marks-generate-bulk|marks-generate-single',
                },
            ]
        },
        {
            name: 'Activities',
            path: '/exam',
            icon: 'grain',
            permission: 'exam-read',
            children: [
                {
                    name: 'Entrance Card',
                    path: '/entrance-card',
                    icon: 'library_books',
                    permission: 'admit-card-generate-bulk|admit-card-generate-single',
                },
                {
                    name: 'Marks Entry',
                    path: '/marks-entry',
                    icon: 'format_indent_increase',
                    permission: 'marks-input-read|marks-input-create|marks-input-update|marks-input-delete',
                },
                {
                    name: 'Individual Marks Entry',
                    path: '/individual-marks-entry',
                    icon: 'face',
                    permission: 'marks-generate-bulk|marks-generate-single',
                },
                {
                    name: 'P/A Entry',
                    path: '/pa-entry',
                    icon: 'format_indent_increase',
                    permission: 'marks-generate-bulk|marks-generate-single',
                },
                {
                    name: 'Result Blocked List',
                    path: '/result-blocked-student-list',
                    icon: 'speaker_notes_off',
                    permission: false,
                }
            ]
        },
        {
            name: 'ECA',
            path: '/eca-head',
            icon: 'grain',
            permission: 'exam-read',
            callBackFunc: NavFactory.isEca,
            children: [
                {
                    name: 'ECA Head',
                    path: '/eca-head',
                    icon: 'card_travel',
                    permission: 'exam-read',
                }, {
                    name: 'ECA Exam Setting',
                    path: '/eca-exam-list',
                    icon: 'settings',
                    permission: 'exam-read',
                },{
                    name: 'ECA Achievement',
                    path: '/eca-achievement',
                    icon: 'description',
                    permission: 'exam-read',
                },

            ]
        }, {
            name: 'Reports',
            path: '/exam',
            icon: 'grain',
            permission: 'exam-read',
            children: [
                {
                    name: 'Grade Sheet & Ledger',
                    path: '/marksheet',
                    icon: 'table_chart',
                    permission: 'marks-generate-bulk|marks-generate-single',
                },

                {
                    name: 'Certificate',
                    path: '/certificate',
                    icon: 'insert_drive_file',
                    permission: 'marks-generate-bulk|marks-generate-single',
                }
            ]
        },

    ]
    //selvesan
    //commit
}];

export default routes;