let routes = [
    {
        name: 'Billing',
        icon: 'monetization_on',
        header: true,
        permission: 'fee-heads-read|fee-read|fee-offer-read|bill-generate-student-wise|bill-generate-class-wise',
        children: [
            {
                name: 'Setting',
                path: '/studentwise-bill-generate',
                icon: 'payment',
                permission: 'bill-generate-student-wise',
                children: [
                    {
                        name: 'Add Previous Due',
                        path: '/previous-due',
                        icon: 'subtitles',
                        permission: 'due-read'
                    },
                    {
                        name: 'Fee Heads',
                        path: '/fee-heads',
                        icon: 'layers',
                        permission: 'fee-heads-read'
                    },
                    {
                        name: 'Fee Charge',
                        path: '/fee',
                        icon: 'line_style',
                        permission: 'fee-read'
                    },
                    {
                        name: 'Fee Offer/Discount',
                        path: '/fee-offer',
                        icon: 'receipt',
                        permission: 'fee-offer-read'
                    },
                    {
                        name: 'Penalty & Rebate',
                        path: '/discount-penalty',
                        icon: 'monetization_on',
                        permission: 'discount-and-penalty-read'
                    }
                ]
            },
            {
                name: 'Activity',
                path: '/receipt-report',
                icon: 'subject',
                permission: 'receipt-read',
                children: [
                    {
                        name: 'Bill Generate',
                        path: '/bill-generate',
                        icon: 'message',
                        permission: 'bill-generate-class-wise'
                    },
                    // {
                    //     name: 'Billing To Account',
                    //     path: '/billing-to-account',
                    //     icon: 'movie',
                    //     permission: 'bill-generate-verify'
                    // },
                    {
                        name: 'Advance Payment',
                        path: '/advance-payment',
                        icon: 'featured_play_list',
                        permission: 'bill-generate-verify'
                    },
                    {
                        name: 'Advance Journal',
                        path: '/advance-journal',
                        icon: 'featured_play_list',
                        permission: 'bill-generate-verify'
                    },
                    {
                        name: 'Posting (SOD/EOD)',
                        path: '/sod',
                        icon: 'list_alt',
                        permission: 'sod-read'
                    },
                    {
                        name: 'Fee Receipt',
                        path: '/fee-payment',
                        icon: 'message',
                        permission: 'receipt-read'
                    }, {
                        name: 'Fee General Receipt',
                        path: '/fee-general-receipt',
                        icon: 'art_track',
                        permission: 'receipt-read'
                    },
                ]
            },

            {
                name: 'Reports',
                path: '/mapping',
                icon: 'payment',
                permission: 'receipt-read',
                children: [
                    {
                        name: 'Previous Due Report',
                        path: '/previous-due-report',
                        icon: 'subject',
                        permission: 'receipt-read'
                    }, {
                        name: 'Receipt Report',
                        path: '/receipt-report',
                        icon: 'subject',
                        permission: 'receipt-read'
                    }, {
                        name: 'Collection Report',
                        path: '/collection-due',
                        icon: 'business',
                        permission: 'billing-report-collection'
                    },
                    // {
                    //     name: 'EOD Collection Report',
                    //     path: '/eod-collection-report',
                    //     icon: 'view_array',
                    //     permission: 'billing-report-collection'
                    // },
                    {
                        name: 'Title Wise EOD Collection Report',
                        path: '/title-wise-eod-collection-report',
                        icon: 'view_array',
                        permission: 'billing-report-collection'
                    }, {
                        name: 'Fee Offer Report',
                        path: '/fee-offer-report',
                        icon: 'view_array',
                        permission: 'billing-report-collection'
                    }, {
                        name: 'Statement',
                        path: '/statement',
                        icon: 'vertical_split',
                        permission: 'billing-report-statement'
                    }, {
                        name: 'Student Bill Due Report',
                        path: '/student-due-report',
                        icon: 'business',
                        permission: 'billing-report-collection'
                    }, {
                        name: 'Summary Report',
                        path: '/summary-report',
                        icon: 'business',
                        permission: 'billing-report-collection'
                    },
                ]
            }]
    }
];

export default routes;