let routes = [{
    name: 'Transportation',
    icon: 'directions_bus',
    header: true,
    permission: 'vehicle-read|route-read|fare-read|report-read',
    children: [
        {
            name: 'Setting',
            path: '',
            icon: '',
            permission: 'fare-read|vehicle-read|route-read',
            children: [
                {
                    name: 'Vehicles',
                    path: '/vehicles',
                    icon: 'layers',
                    permission: 'vehicle-read'
                },
                {
                    name: 'Routes',
                    path: '/transportation-routes',
                    icon: 'traffic',
                    permission: 'route-read'
                }, {
                    name: 'Destination & Fare',
                    path: '/route-fare',
                    icon: 'money',
                    permission: 'fare-read'
                },
            ]
        },
        {
            name: 'Activity',
            path: '',
            icon: '',
            permission: 'fare-read|vehicle-read|route-read',
            children: [
                {
                    name: 'Manage Transport',
                    path: '/manage-transport',
                    icon: 'departure_board',
                    permission: 'fare-read'
                },
                // {
                //     name: 'Migrate Transport',
                //     path: '/migrate-student-transport',
                //     icon: '',
                //     permission: 'fare-read'
                // },
            ]
        },
        {
            name: 'Report',
            path: '',
            icon: '',
            permission: 'fare-read|vehicle-read|route-read',
            children: [
                {
                    name: 'Transport Report',
                    path: '/transport-report',
                    icon: 'receipt',
                    permission: 'fare-read'
                }]
        }
    ]
}];

export default routes;